<template>
  <div>
    <div class="container">
      <b-row>
        <b-col sm="3" cols="6">
          <b-card class="shadow mb-3" @click="redirect('domain')">
            <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'kt-svg-icon kt-svg-icon--primary svg-icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z"/><circle fill="#000" opacity=".3" cx="12" cy="12" r="9"/><path d="M11.736 20.996a9 9 0 01-8.733-9.245c.66.063 1.853.206 1.91.67.077.64 0 1.44.545 1.68s.701-.96 1.169-.64c.467.32 1.714.629 1.714 1.195 0 .566-.234 1.447 0 1.608.234.16 1.168 1.28 1.168 1.44 0 .161.33.975.231 1.287-.08.258-.491 1.06-.89 1.424a8.742 8.742 0 002.886.581zM8.283 3.801A8.967 8.967 0 0112 3a9 9 0 018.036 4.944c.034.78.028 1.447.2 1.689.877 1.227-2.136 2.071-1.679 3.928.202.818-2.004.559-2.543 1.325-.54.767-1.857.252-2.162.104-.305-.147-1.475.344-1.373-.49.102-.835.813-.884 1.577-1.228.762-.343 1.863-1.485 1.322-1.634-2.546-.702-3.414-3.16-3.414-3.16-.153-.029-.115-1.737-.776-1.786-.661-.05-1.018.196-1.984 0s-.763-.835-.915-2.21c-.034-.308-.033-.525-.006-.68zm12.716 8.076L21 12a9 9 0 01-8.91 9c4.88-.05 8.844-4.112 8.91-9.123z" fill="#000" opacity=".3"/></g></svg>
            <div class="subtitle">Domain</div>
            <div class="desc">
              <div v-if="loading">
                <v-skeleton-loader
                  type="text"
                  max-width="100px"
                ></v-skeleton-loader>
              </div>
              <div v-else>{{ sum.domain }} Order</div>
            </div>
          </b-card>
        </b-col>
        <b-col sm="3" cols="6">
          <b-card class="shadow" @click="redirect('hosting')">
            <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'kt-svg-icon kt-svg-icon--primary svg-icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z"/><path d="M2 13h20v5a2 2 0 01-2 2H4a2 2 0 01-2-2v-5zm16.5 5a1.5 1.5 0 100-3 1.5 1.5 0 000 3zm-5 0a1.5 1.5 0 100-3 1.5 1.5 0 000 3z" fill="#000"/><path d="M5.793 8h12.414a1 1 0 01.839.455L22 13H2l2.954-4.545A1 1 0 015.793 8z" fill="#000" opacity=".3"/></g></svg>
            <div class="subtitle">Hosting</div>
            <div class="desc">
              <div v-if="loading">
                <v-skeleton-loader
                  type="text"
                  max-width="100px"
                ></v-skeleton-loader>
              </div>
              <div v-else>{{ sum.hosting }} Order</div>
            </div>
          </b-card>
        </b-col>
        <b-col sm="3" cols="6">
          <b-card class="shadow" @click="redirect('email')">
            <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'kt-svg-icon kt-svg-icon--primary svg-icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z"/><path d="M4.875 20.75a.946.946 0 01-.67-.288l-1.917-1.916a.926.926 0 010-1.342c.383-.383 1.006-.383 1.341 0l1.246 1.246 3.162-3.162a.926.926 0 011.342 0 .926.926 0 010 1.341l-3.833 3.833a.946.946 0 01-.671.288z" fill="#000" fill-rule="nonzero" opacity=".3"/><path d="M12.984 18A7.5 7.5 0 004 11.15V7a2 2 0 012-2h14a2 2 0 012 2v9a2 2 0 01-2 2h-7.016zm6.16-11.16L13 10.148 6.856 6.84a.75.75 0 10-.712 1.32l6.5 3.5c.222.12.49.12.712 0l6.5-3.5a.75.75 0 00-.712-1.32z" fill="#000"/></g></svg>
            <div class="subtitle">Email</div>
            <div class="desc">
              <div v-if="loading">
                <v-skeleton-loader
                  type="text"
                  max-width="80px"
                ></v-skeleton-loader>
              </div>
              <div v-else>{{ sum.email }} Order</div>
            </div>
          </b-card>
        </b-col>
        <b-col sm="3" cols="6">
          <b-card class="shadow" @click="redirect('other')">
            <div class="dropdown dropdown-inline">
              <button
                style="width: 38px; height: 38px"
                type="button"
                class="btn btn-clean btn-sm btn-icon btn-icon-md"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'kt-svg-icon kt-svg-icon--primary svg-icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z"/><circle fill="#000" cx="5" cy="12" r="2"/><circle fill="#000" cx="12" cy="12" r="2"/><circle fill="#000" cx="19" cy="12" r="2"/></g></svg>
              </button>
              <div
                class="
                  dropdown-menu
                  dropdown-menu-right
                  dropdown-menu-fit
                  dropdown-menu-md
                "
              >
                <ContextMenu1></ContextMenu1>
              </div>
            </div>
            <div class="subtitle">Lainnya</div>
            <div class="desc">
              <div v-if="loading">
                <v-skeleton-loader
                  type="text"
                  max-width="80px"
                ></v-skeleton-loader>
              </div>
              <div v-else>{{ sum.other }} Order</div>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import { ApiURL, util, loading } from "@/common/mixins/general.js";
import ContextMenu1 from "@/views/partials/widgets/ContextMenu1.vue";
export default {
  name: "slot-product-all",
  mixins: [ApiURL, util, loading],
  components: {
    ContextMenu1
  },
  data() {
    return {
      loading: true,
      sum: {}
    };
  },
  methods: {
    redirect(name) {
      let product_allowed = ["domain", "hosting", "email"];
      if (product_allowed.includes(name)) {
        this.mx_redirect({ path: "/product/" + name });
      } else {
        this.get_redirect();
      }
    },

    /**
     * -----------------------------------------
     * get sum product general
     * -----------------------------------------
     */
    get_sum() {
      this.xhr_product.get(this.api.product.general.sum).then(({ data }) => {
        this.sum = data.data.sum;
        this.loading = false;
      });
    },

    /**
     * -----------------------------------------
     * get redirect to whmcs
     * -----------------------------------------
     */
    get_redirect() {
      this.mx_loading();
      this.xhr_tools
        .get(this.api.tools.redirect.index, "?file=clientarea&action=services")
        .then(({ data }) => {
          this.mx_redirect(data.data.url, false);
        })
        .finally(() => {
          this.mx_loading(false);
        });
    }
  },
  mounted() {
    this.get_sum();
  }
};
</script>
<style scoped>
#lainnya {
  overflow: unset;
}

.svg-icon {
  width: 38px;
  height: 38px;
}

.subtitle {
  font-size: 1.2rem;
  font-weight: 500;
  color: #595d6e;
}

.desc {
  font-size: 1rem;
  color: #74788d;
}

.col-sm-3 .card {
  cursor: pointer;
}

.col-sm-3 .card:hover {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}
</style>
