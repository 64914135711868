<template>
  <ul class="kt-nav">
    <li class="kt-nav__section kt-nav__section--first">
      <span class="kt-nav__section-text">SSl</span>
    </li>
    <li class="kt-nav__item">
      <a href="#" class="kt-nav__link">
        <i class="kt-nav__link-icon flaticon2-graph-1"></i>
        <span class="kt-nav__link-text">SECTIGO</span>
      </a>
    </li>
    <li class="kt-nav__item">
      <a href="#" class="kt-nav__link">
        <i class="kt-nav__link-icon flaticon2-calendar-4"></i>
        <span class="kt-nav__link-text">GlobalSign</span>
      </a>
    </li>
    <li class="kt-nav__item">
      <a href="#" class="kt-nav__link">
        <i class="kt-nav__link-icon flaticon2-layers-1"></i>
        <span class="kt-nav__link-text">Entrust Datacard</span>
      </a>
    </li>
    <li class="kt-nav__section">
      <span class="kt-nav__section-text">Email & Kolaborasi</span>
    </li>
    <li class="kt-nav__item">
      <a href="#" class="kt-nav__link">
        <i class="kt-nav__link-icon flaticon2-calendar-4"></i>
        <span class="kt-nav__link-text">Google Suite</span>
      </a>
    </li>
    <li class="kt-nav__item">
      <a href="#" class="kt-nav__link">
        <i class="kt-nav__link-icon flaticon2-file-1"></i>
        <span class="kt-nav__link-text">FlockMail</span>
      </a>
    </li>
    <li class="kt-nav__item">
      <a href="#" class="kt-nav__link">
        <i class="kt-nav__link-icon flaticon2-file-1"></i>
        <span class="kt-nav__link-text">Alibaba Mail</span>
      </a>
    </li>
  </ul>
</template>

<script>
export default {
  name: "context-menu-1",
  components: {},
  mounted() {}
};
</script>
