<template>
  <div>
    <RWCard>
      <template v-slot:title>
        <h3 class="kt-portlet__head-title">
          <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'kt-svg-icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z"/><rect fill="#000" x="3" y="13" width="18" height="7" rx="2"/><path d="M17.403 9.55l-1.543 1.272A4.987 4.987 0 0012 9a4.987 4.987 0 00-3.85 1.81L6.61 9.535A6.986 6.986 0 0112 7c2.117 0 4.082.947 5.403 2.55zm3.065-2.571L18.93 8.256A8.978 8.978 0 0012 5a8.978 8.978 0 00-6.922 3.248L3.54 6.968A10.977 10.977 0 0112 3c3.315 0 6.392 1.477 8.468 3.979z" fill="#000" fill-rule="nonzero" opacity=".3"/></g></svg>
          Products &amp; Services
        </h3>
      </template>

      <!-- body -->
      <template v-slot:body>
        <RWProduct></RWProduct>
      </template>
    </RWCard>

    <b-card>
      <template v-slot:header>
        <b-form inline @submit.stop.prevent="search">
          <label class="sr-only">Domain Name</label>
          <b-input
            id="inline-form-input-name"
            class="mb-2 mr-sm-2 mb-sm-0"
            placeholder="eq. mydomain.id"
            v-model="form_search.domain"
            autocomplete="off"
          ></b-input>

          <b-form-select
            class="mb-2 mr-sm-2 mb-sm-0"
            id="input-3"
            required
            :options="status"
            v-model="form_search.status"
            @change="search()"
          ></b-form-select>

          <b-button type="submit" variant="primary">Find</b-button>
        </b-form>
      </template>

      <div v-if="loading.table">
        <v-skeleton-loader
          type="table-thead, table-row-divider@4, table-tfoot"
        ></v-skeleton-loader>
      </div>
      <div v-else>
        <div v-if="items.length != 0">
          <b-skeleton-table
            :columns="5"
            :rows="pagination.limit"
            v-if="loading.pagination"
            :table-props="{ striped: true }"
          ></b-skeleton-table>
          <b-table
            v-else
            striped
            hover
            selectable
            :items="items"
            :fields="list_product.fields"
            select-mode="single"
            @row-selected="row_selected"
          >
            <template v-slot:cell(no)="data">
              <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" :class="mx_gear_color(data.item.status)" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z"/><path d="M18.622 9.75h.128a2.25 2.25 0 110 4.5h-.065a.488.488 0 00-.446.295.488.488 0 00.089.54l.045.044a2.25 2.25 0 11-3.183 3.184l-.04-.04a.49.49 0 00-.545-.094.486.486 0 00-.295.444v.127a2.25 2.25 0 11-4.5 0 .524.524 0 00-.363-.514.485.485 0 00-.532.092l-.044.045a2.25 2.25 0 11-3.184-3.183l.04-.04a.49.49 0 00.094-.545.486.486 0 00-.443-.295H5.25a2.25 2.25 0 110-4.5.524.524 0 00.514-.363.485.485 0 00-.092-.532l-.045-.044A2.25 2.25 0 118.81 5.687l.04.04c.142.139.355.177.537.097l.108-.023a.486.486 0 00.255-.423V5.25a2.25 2.25 0 114.5 0v.065c0 .194.117.37.303.449.182.08.395.042.532-.092l.044-.045a2.25 2.25 0 113.184 3.183l-.04.04a.488.488 0 00-.097.537l.023.108a.486.486 0 00.423.255z" fill="#000" fill-rule="nonzero" opacity=".3"/><path d="M12 15a3 3 0 100-6 3 3 0 000 6z" fill="#000"/></g></svg>
            </template>

            <!-- name -->
            <template v-slot:cell(name)="data">
              <h6>{{ data.value }}</h6>
              <a :href="data.item.domain" class="text-dark" target="_blank">{{
                data.item.domain
              }}</a>
            </template>

            <!-- pricing -->
            <template v-slot:cell(price)="data">
              <h6>{{ data.value }}</h6>
              {{ data.item.recurring }}
              <span v-if="data.item.type == 'domain'">Year<sup>(s)</sup></span>
            </template>

            <!-- duedate -->
            <template v-slot:cell(duedate)="data">
              <p>{{ data.value }}</p>
            </template>

            <!-- button -->
            <template v-slot:cell(status)="data">
              <b-link @click="redirect(data.item.type, data.item.id)">
                <b-button
                  style="color: white; text-transform: capitalize"
                  :variant="mx_status_color(data.value)"
                  block
                  >{{ data.value }}
                </b-button>
              </b-link>
            </template>
          </b-table>

          <!-- pagination -->
          <b-pagination
            v-model="pagination.current"
            :total-rows="pagination.allCount"
            :per-page="pagination.limit"
            @change="handlePagination"
            align="right"
            first-text="First"
            prev-text="Prev"
            next-text="Next"
            last-text="Last"
          ></b-pagination>
        </div>
        <div v-else>
          <b-alert variant="warning" show>Product not found</b-alert>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import RWCard from "@/views/partials/content/Portlet.vue";
import RWProduct from "@/views/pages/product/slot/slotProductAll.vue";
import { productMixin } from "@/common/mixins/product/product-mixin.js";
import {
  breadcrumb,
  ApiURL,
  notif,
  util,
  loading
} from "@/common/mixins/general.js";
export default {
  name: "rw-product-all",
  mixins: [productMixin, breadcrumb, ApiURL, notif, util, loading],
  components: {
    RWCard,
    RWProduct
  },
  data() {
    return {
      loading: {
        table: true,
        pagination: false
      },
      URLWhmcs: "",
      form_search: {
        domain: "",
        status: "All"
      },
      status: [
        "All",
        "Active",
        "Cancelled",
        "Pending",
        "Suspended",
        "Terminated"
      ],
      items: null,
      pagination: {
        limit: 5,
        offset: 0,
        current: 1,
        allCount: 0
      }
    };
  },
  methods: {
    /**
     * -----------------------------------------
     * generate /submit pencarian (domain, status)
     * -----------------------------------------
     */
    search() {
      this.pagination.offset = 0;
      this.pagination.current = 1;
      this.loading.table = true;
      this.get_allproduct();
    },

    /**
     * -----------------------------------------
     * get all product general
     * -----------------------------------------
     */
    get_allproduct() {
      let query = this.mx_ObjectToQuery(this.query_request, "?");

      this.xhr_product
        .get(this.api.product.general.all, query)
        .then(({ data }) => {
          this.items = data.data.product.record;
          this.pagination.allCount = data.data.product.count;
        })
        .catch(({ response }) => {
          this.mx_toast(response.data.message, response.status, {
            variant: "danger"
          });

          // handle jika ketika next page ada error
          this.pagination.current =
            this.pagination.current !== 1 ? this.pagination.current - 1 : 1;
        })
        .finally(() => {
          this.loading.table = false;
          this.loading.pagination = false;
        });
    },

    /**
     * -----------------------------------------
     * redirect to list product whmcs
     * karna rwreborn belum ada view nya
     * -----------------------------------------
     */
    get_redirect_whmcs() {
      this.mx_loading();
      this.xhr_tools
        .get(this.api.tools.redirect.index, "?file=clientarea&action=services")
        .then(({ data }) => {
          this.mx_redirect(data.data.url, false);
        })
        .finally(() => {
          this.mx_loading(false);
        });
    },

    /**
     * -------------------------------------------------
     * ketika klik select barisnya
     * -------------------------------------------------
     */
    row_selected(item) {
      this.redirect(item[0].type, item[0].id);
    },

    /**
     * -------------------------------------------------
     * ketika klik action button
     * -------------------------------------------------
     */
    redirect(type, id) {
      if (type === "domain" || type === "hosting" || type === "email") {
        this.mx_redirect({ path: "/product/" + type + "/" + id });
      } else {
        this.get_redirect_whmcs();
      }
    },

    /**
     * ------------------------------------------------
     * handle pagination next/prev
     * ------------------------------------------------
     */
    handlePagination: function(page) {
      this.loading.pagination = true;
      this.pagination.offset = (page - 1) * this.pagination.limit;

      this.get_allproduct();
    }
  },
  computed: {
    allRow() {
      return this.items.length;
    },

    /**
     * -------------------------------------------------
     * generate query request data
     * -------------------------------------------------
     */
    query_request: function() {
      let query = new Object();
      query.limit = this.pagination.limit;
      query.offset = this.pagination.offset;

      if (this.form_search.status !== "All") {
        query.status = this.form_search.status;
      }
      if (this.form_search.domain !== "") {
        query.search = this.form_search.domain;
      }
      return query;
    }
  },
  mounted() {
    this.get_allproduct();
    // this.get_redirect_whmcs();
    this.set_breadcrumb([{ title: "Product", route: "product" }]);
  }
};
</script>
